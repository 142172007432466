@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch&family=Montserrat:wght@100&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;



.transition-opacity {
    transition: opacity 1s ease-in-out;
  }
  
  /* Home.css */
  .fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .rec.rec-arrow-right:hover {
    background-color: black !important;
    color: white !important
  }
  .rec.rec-arrow-right {
    background-color: white !important;
    color: black !important
  }
  .rec.rec-arrow-left {
    background-color: white !important;
    color: black !important
  }

  .rec.rec-arrow-left:hover {
    background-color: black !important;
    color: white !important
  }


  .rec.rec-dot {
    border: black !important;
    background-color: black !important;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.5);
}

  .rec.rec-dot_active {
    background-color: white !important;
    border-style: solid !important;
    border-color: black !important;
    box-shadow: 0 0 1px 3px rgb(0, 0, 0);
  }

